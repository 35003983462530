import Section from '../../../../../components/Section';
import Step from '../../../../../components/Steps/Step';
import styles from '../../../../../components/Steps/Steps.module.scss';

import iconUser from '../../../components/Steps/icons/icon-user.svg';
import iconMath from '../../../components/Steps/icons/icon-math.svg';
import iconFace from '../../../components/Steps/icons/icon-face.png';
import iconTask from '../../../components/Steps/icons/icon-task.svg';
import iconVerified from '../../../components/Steps/icons/icon-verified.svg';
import iconCriminal from '../../../components/Steps/icons/icon-cybercriminals.svg';

const Steps = () => (
  <Section id="Steps" className={styles.Steps}>
    <Step idx={1} image={iconUser}>
      <p>
        We use our open-source JavaScript to gather the user's cursor movement
        data to create a comprehensive biometric profile.
      </p>
    </Step>

    <Step idx={2} image={iconMath} inverse>
      <p>
        The anonymized data is securely sent to our servers, where we analyze
        the dynamic characteristics of cursor movements.
      </p>
    </Step>

    <Step idx={3} image={iconFace}>
      <p>
        Once enough data is collected, our deep-tech adaptive algorithms build
        unique biometric profiles.
      </p>
    </Step>

    <Step idx={4} image={iconTask} inverse>
      <p>
        The system compares the user's real-time behavior patterns with their
        biometric profile and determines an Identity Score, updated several
        times a minute.
      </p>
    </Step>

    <Step idx={5} image={iconVerified}>
      <p>
        When a user's Identity Score drops below the threshold, Graboxy locks
        the opened tabs in the browser.
      </p>
    </Step>

    <Step idx={6} image={iconCriminal} inverse showSeparator={false}>
      <p>
        You can unlock the tabs with your PIN.
      </p>
    </Step>
  </Section>
);

export default Steps;
