import { Link } from 'react-scroll';
import AppLayout from '../../../layouts/AppLayout';
import Section from '../../../components/Section';
import Awards from '../../../components/Awards';
import Blog from '../../../../blog/components/Blog';
import Contact from '../../../components/Contact';
import styles from './DeviceFingerprinting.module.scss';

import verified from '../components/icons/verified.svg';
import chart from '../components/icons/chart36.svg';
import graph from '../components/icons/graph230.svg';
import coins from '../components/icons/coins12.svg';

import hardware from '../components/gifs/hardware.gif';
import geolocation from '../components/gifs/geolocation.gif';
import network from '../components/gifs/network.gif';
import behavior from '../components/gifs/behavior.gif';
import user from '../components/gifs/user.gif';
import software from '../components/gifs/software.gif';
import reach from '../components/gifs/reach.gif';
import ux from '../components/gifs/ux.gif';
import gdpr from '../components/gifs/gdpr.gif';
import account from '../components/gifs/account.gif';
import fraudulent from '../components/gifs/fraudulent.gif';
import credential from '../components/gifs/credential.gif';
import phishing from '../components/gifs/phishing.gif';
import malware from '../components/gifs/malware.gif';
import botnet from '../components/gifs/botnet.gif';

const DeviceFingerprinting = () => {
  return (
    <AppLayout>
      <div className={styles.DeviceFingerprinting}>
        <div className={styles.GradientBg}>
          <div className={styles.Hero}>
            <Section title="Device Fingerprinting">
              <div className="container">
                <div className="row mt-3 mb-5 pb-3">
                  <div className="col-12 text-center">
                    <img src={verified} />
                  </div>
                  <div className="col-12 text-center">
                    <h3>
                      Approve logins & transactions invisibly and
                      <br />
                      prevent website spoofing or mirror phishing fraud
                    </h3>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-12 mb-2">
                    <h3>Instant Time to Value</h3>
                  </div>
                  <div className="col-12 mb-4">
                    <p>
                      Works right after the first user session
                    </p>
                  </div>
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={ux} width={100} className="mb-4" />
                      <h4>Frictionless UX</h4>
                      <p>
                        Enhances security without compromising the user
                        experience
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={reach} width={100} className="mb-4" />
                      <h4>Zero Trust Support</h4>
                      <p>
                        Helps to reach your Zero Trust security goals by
                        continuously verifying user identities
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={gdpr} width={100} className="mb-4" />
                      <h4>Compliant with NIS2,<br />PSD2, and SCA</h4>
                      <p>
                        Graboxy is compliant with NIS2, PSD2, SCA and offers
                        OAuth2 integration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <Link to="Contact" className="btn" spy smooth>
                      Request Demo
                    </Link>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 pt-3 text-center">
                    <h4>
                      Graboxy Device Fingerprinting is part of the Graboxy AI
                      Cybersecurity Platform.
                    </h4>
                    <h4>
                      Learn more about the other features such as
                      {' '}
                      <br />
                      {' '}
                      <a
                        href="/solutions/remote-desktop-detection"
                        className="mainlink"
                      >
                        Remote Desktop Detection
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/continuous-authentication"
                        className="mainlink"
                      >
                        Continuous Authentication
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/multi-factor-authentication"
                        className="mainlink"
                      >
                        Silent 2FA
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/captcha-bot-detection"
                        className="mainlink"
                      >
                        CAPTCHA & Bot Detection
                      </a>
                      ,
                      {' '}
                      and
                      {' '}
                      <a
                        href="/solutions/chrome-extension"
                        className="mainlink"
                      >
                        Chrome Extension
                      </a>.
                    </h4>
                  </div>
                </div>
              </div>
            </Section>
          </div>
        </div>
        <div className={styles.Awards}>
          <Awards />
        </div>
        <div className={styles.GradientBg}>
          <div className={styles.HowItWorks}>
            <Section
              className={styles.DeviceFingerprinting}
              title="How It Works"
            >
              <div className="container">
                <div className="row text-center">
                  <div className="col-lg-4 mb-4">
                    <div className={styles.Box}>
                      <img src={behavior} width={100} className="mb-3" />
                      <h4>Behavioral Patterns</h4>
                      <p>
                        Patterns of user behavior, such as  session duration,
                        and interaction with the service
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className={styles.Box}>
                      <img src={geolocation} width={100} className="mb-3" />
                      <h4>Geolocation</h4>
                      <p>
                        The physical location of the device based on its IP
                        address or GPS coordinates
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className={styles.Box}>
                      <img src={network} width={100} className="mb-3" />
                      <h4>Network Information</h4>
                      <p>
                        Data related to the device's IP address, network type,
                        and connection speed
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className={styles.Box}>
                      <img src={hardware} width={100} className="mb-3" />
                      <h4>Hardware Configuration</h4>
                      <p>
                        Information about the device's hardware components,
                        such as its processor, graphics card, and memory
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className={styles.Box}>
                      <img src={user} width={100} className="mb-3" />
                      <h4>User Agent</h4>
                      <p>
                        Information sent by the device's browser or application,
                        which includes details like the device's user agent
                        string, screen resolution, and language settings
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className={styles.Box}>
                      <img src={software} width={100} className="mb-3" />
                      <h4>Software Settings</h4>
                      <p>
                        Details about the device's operating system, browser
                        version, installed plugins or extensions, and other
                        software configurations
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </div>
        </div>
        <div className={styles.Enhance}>
          <Section title="Enhance Your Cybersecurity">
            <div className="container">
              <div className="row text-center">
                <div className="col-12 mb-5">
                  <h4>
                    Device fingerprinting enhances security measures by
                    providing organizations with valuable insights into the
                    devices accessing their networks or services, enabling them
                    to detect and prevent a wide range of cyber attacks more
                    effectively.
                  </h4>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-lg-4 mb-4">
                  <div className={styles.Polygon}>
                    <img src={account} width={70} />
                  </div>
                  <h4>Account Takeover Attacks</h4>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className={styles.Polygon}>
                    <img src={fraudulent} width={70} />
                  </div>
                  <h4>Fraudulent Transactions</h4>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className={styles.Polygon}>
                    <img src={credential} width={70} />
                  </div>
                  <h4>Credential Stuffing Attacks</h4>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className={styles.Polygon}>
                    <img src={phishing} width={70} />
                  </div>
                  <h4>Phishing Attacks</h4>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className={styles.Polygon}>
                    <img src={malware} width={70} />
                  </div>
                  <h4>Malware Infections</h4>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className={styles.Polygon}>
                    <img src={botnet} width={70} />
                  </div>
                  <h4>Botnet Attacks</h4>
                </div>
              </div>
            </div>
          </Section>
        </div>
        <div className={styles.DidYouKnow}>
          <Section title="Did You Know?">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div className={styles.Box}>
                    <img src={chart} height={85} className="mb-4" />
                    <p>
                      36% of businesses have experienced a security incident
                      caused by the actions of remote workers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div className={styles.Box}>
                    <img src={coins} height={85} className="mb-4" />
                    <p>
                      The average loss of an account takeover fraud is around
                      $12,000 per case.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div className={styles.Box}>
                    <img src={graph} height={85} className="mb-4" />
                    <p>
                      In the most recent annual report, there has been a rise
                      of over 230% in remote access scams compared to the prior
                      year.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
        <div className={styles.Blog}>
          <Blog />
        </div>
        <Contact />
      </div>
    </AppLayout>
  );
};

export default DeviceFingerprinting;
