import AppLayout from '../../../layouts/AppLayout';
import Section from '../../../components/Section';
import Awards from '../../../components/Awards';
import Blog from '../../../../blog/components/Blog';
import Contact from '../../../components/Contact';
import Steps from './components/Steps';
import styles from './ChromeExtension.module.scss';

import lock from '../components/gifs/lock.gif';
import ux from '../components/gifs/ux.gif';
import reach from '../components/gifs/reach.gif';

const ChromeExtension = () => {
  return (
    <AppLayout>
      <div className={styles.ChromeExtension}>
        <div className={styles.GradientBg}>
          <Section
            title="Continuous Authentication for Chrome Users"
            className={styles.Hero}
          >
            <div className="container">
              <div className="row mt-3 mb-5">
                <div className="col-12 text-center">
                  <iframe
                    src="https://player.vimeo.com/video/1047096180?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    width="960"
                    height="540"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                    title="Graboxy Browser Extension">
                  </iframe>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mt-3 mb-5">
                <div className="col-12 text-center">
                  <h3>
                    Prevent unauthorized access and protect online accounts
                    within your browser
                  </h3>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-12 py-3 text-center">
                  <a
                    href="https://chromewebstore.google.com/detail/graboxy-browser-extension/lmbbkcdefcjagapdhdklaajdkllcochm"
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={lock} width={100} alt="defend" />
                    </div>
                    <h4>
                      Enhanced Security
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={reach} width={100} alt="reach" />
                    </div>
                    <h4>
                      24/7 Protection
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={ux} width={100} alt="timer" />
                    </div>
                    <h4>
                      Frictionless UX
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
        <div className={styles.Awards}>
          <Awards />
          <div className="row mb-3 -mt-2">
            <div className="col-12 py-0 text-center">
              <a
                href="https://chromewebstore.google.com/detail/graboxy-browser-extension/lmbbkcdefcjagapdhdklaajdkllcochm"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            </div>
          </div>
        </div>
        <Section
          title="How Our Biometric Authentication System Works"
          className={styles.HowItWorks}
        >
          <div className="container">
            <Steps />
            <div className="row mt-5 mb-0">
              <div className="col-12 pt-3 text-center">
                <a
                  href="https://chromewebstore.google.com/detail/graboxy-browser-extension/lmbbkcdefcjagapdhdklaajdkllcochm"
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </Section>
        <div className={styles.Blog}>
          <Blog />
        </div>
        <div className={styles.Contact}>
          <Contact />
        </div>
      </div>
    </AppLayout>
  );
};

export default ChromeExtension;
