import { Link } from 'react-scroll';
import AppLayout from '../../../layouts/AppLayout';
import Section from '../../../components/Section';
import Awards from '../../../components/Awards';
import Blog from '../../../../blog/components/Blog';
import Contact from '../../../components/Contact';
import Steps from '../components/Steps';
import styles from './MultiFactorAuthentication.module.scss';

import cloud from '../components/gifs/cloud.gif';
import defend from '../components/gifs/defend.gif';
import gdpr from '../components/gifs/gdpr.gif';

import chart from '../components/icons/chart36.svg';
import coins from '../components/icons/coins12.svg';
import graph from '../components/icons/graph230.svg';

const MultiFactorAuthentication = () => {
  return (
    <AppLayout>
      <div className={styles.MultiFactorAuthentication}>
        <div className={styles.GradientBg}>
          <div className={styles.Hero}>
            <Section title="Silent 2FA">
              <div className="container">
                <div className="row mt-3 mb-5">
                  <div className="col-12 text-center">
                    <iframe
                      width="720"
                      height="365"
                      src="https://player.vimeo.com/video/915877801?h=743a4830ca&badge=0&autopause=0&player_id=0&app_id=58479"
                      frameborder="0"
                      allow="autoplay;"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div className="row mt-3 mb-5">
                  <div className="col-12 text-center">
                    <h3>
                      Approve logins & transactions invisibly and
                      <br />
                      prevent website spoofing or mirror phishing fraud
                    </h3>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={cloud} width={100} className="mb-4" />
                      <h4>AI-Driven<br />Scam Detection</h4>
                      <p>
                        Utilizes advanced algorithms to analyze user behavior
                        and identify potential scams
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={defend} width={100} className="mb-4" />
                      <h4>Cannot be stolen<br />or replicated</h4>
                      <p>
                        Biometric profiles created from human movement cannot be
                        stolen or replicated
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={gdpr} width={100} className="mb-4" />
                      <h4>Compliant with NIS2,<br />PSD2, and SCA</h4>
                      <p>
                        Graboxy is compliant with NIS2, PSD2, SCA and offers
                        OAuth2 integration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 pt-3 text-center">
                    <h4>
                      Graboxy Silent 2FA is part of the Graboxy AI
                      Cybersecurity Platform.
                    </h4>
                    <h4>
                      Learn more about the other features such as
                      {' '}
                      <br />
                      <a
                        href="/solutions/remote-desktop-detection"
                        className="mainlink"
                      >
                        Remote Desktop Detection
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/continuous-authentication"
                        className="mainlink"
                      >
                        Continuous Authentication
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/device-fingerprinting"
                        className="mainlink"
                      >
                        Device Fingerprinting
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/captcha-bot-detection"
                        className="mainlink"
                      >
                        CAPTCHA & Bot Detection
                      </a>
                      ,
                      {' '}
                      and
                      {' '}
                      <a
                        href="/solutions/chrome-extension"
                        className="mainlink"
                      >
                        Chrome Extension
                      </a>.
                    </h4>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <Link to="Contact" className="btn" spy smooth>
                      Request Demo
                    </Link>
                  </div>
                </div>
              </div>
            </Section>
          </div>
        </div>
        <div className={styles.Awards}>
          <Awards />
        </div>
        <Section
          title="Case Study"
          className={styles.CaseStudy}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div>
                  <h3 className="mb-4">
                    Continuous Online User Authentication in Banking
                  </h3>
                  <p className="mb-4">
                    Goal: Decrease the number of fraudulent online banking
                    transactions and reduce the cost of transaction
                    authorization
                  </p>
                  <div className={styles.Results}>
                    <div className="d-md-flex justify-content-center my-5">
                      <div className={styles.ResultItem}>
                        <p>
                          Cost saving on
                          <br />
                          OTPs via SMS:
                        </p>
                        <h3>-85%</h3>
                      </div>
                      <div className={styles.ResultItem}>
                        <p>Fraud detection accuracy*:</p>
                        <h3>97%</h3>
                        <span>
                          * Where sufficient training data was available.
                        </span>
                      </div>
                      <div className={styles.ResultItem}>
                        <p>Authenticated user sessions daily:</p>
                        <h3>100K+</h3>
                      </div>
                    </div>
                  </div>
                  <a href="/case-study" className="btn">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section
          title="How It Works"
          className={styles.HowItWorks}
        >
          <div className="container">
            <Steps />
          </div>
        </Section>
        <Section
          title="Did You Know?"
          className={styles.DidYouKnow}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={coins} className="mb-4" height={75} />
                  <p>
                    The average loss of an account takeover fraud is around
                    $12,000 per case.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={chart} className="mb-4" height={75} />
                  <p>
                    36% of businesses have experienced a security incident
                    caused by the actions of remote workers.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={graph} className="mb-4" height={75} />
                  <p>
                    In the most recent annual report, there has been a rise of
                    over 230% in remote access scams compared to the prior
                    year.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <div className={styles.Blog}>
          <Blog />
        </div>
        <Contact />
      </div>
    </AppLayout>
  );
};

export default MultiFactorAuthentication;
