import { Link } from 'react-scroll';
import AppLayout from '../../../layouts/AppLayout';
import Section from '../../../components/Section';
import Awards from '../../../components/Awards';
import Blog from '../../../../blog/components/Blog';
import Contact from '../../../components/Contact';
import styles from './RemoteDesktopDetection.module.scss';

import cybercriminal from '../components/icons/cybercriminal.svg';
import coins from '../components/icons/coins806.svg';
import graph from '../components/icons/graph230.svg';
import chart from '../components/icons/chart22.svg';

import timer from '../components/gifs/timer.gif';
import ux from '../components/gifs/ux.gif';
import cloud from '../components/gifs/cloud.gif';
import support from '../components/gifs/support.gif';
import access from '../components/gifs/access.gif';
import system from '../components/gifs/system.gif';
import phone from '../components/gifs/phone.gif';
import document from '../components/gifs/document.gif';
import error from '../components/gifs/error.gif';
import fraudulent from '../components/gifs/fraudulent.gif';

const RemoteDesktopDetection = () => {
  return (
    <AppLayout>
      <div className={styles.RemoteDesktopDetection}>
        <div className={styles.GradientBg}>
          <div className={styles.Hero}>
            <Section title="Remote Desktop Detection">
              <div className="container">
                <div className="row mt-3 mb-5">
                  <div className="col-12 text-center">
                    <iframe
                      width="720"
                      height="365"
                      src="https://player.vimeo.com/video/952305360?h=de124ad664"
                      frameborder="0"
                      allow="autoplay;"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div className="row mt-lg-5">
                  <div className="col-12 text-center">
                    <h3>
                      Stop tech support scams, AnyDesk, or
                      {' '}
                      <br />
                      other Remote Desktop Protocol Attacks within 5 seconds
                    </h3>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 text-center mb-4">
                    <h3>Instant value</h3>
                    <p>
                      Works immediately out of the box with pre-defined AI
                      models.
                    </p>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <div className={styles.Box}>
                      <img src={cloud} width={100} className="mb-3" />
                      <h4>
                        AI-Driven Scam Detection
                      </h4>
                      <p>
                        Advanced AI analyzes user behavior
                        {' '}
                        <br />
                        and identifies potential scams
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <div className={styles.Box}>
                      <img src={ux} width={100} className="mb-3" />
                      <h4>
                        Frictionless UX
                      </h4>
                      <p>
                        Enhances security
                        {' '}
                        <br />
                        without compromising the user experience
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <div className={styles.Box}>
                      <img src={timer} width={100} className="mb-3" />
                      <h4>
                        Zero Trust Support
                      </h4>
                      <p>
                        Helps to reach your Zero Trust goals by continuously
                        verifying user identities
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-12 py-3 text-center">
                    <Link to="Contact" className="btn" spy smooth>
                      Book a Demo
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pt-3 text-center">
                    <h4>
                      Graboxy Remote Desktop Detection is part of the Graboxy AI
                      Cybersecurity Platform.
                    </h4>
                    <h4>
                      Learn more about the other features, such as
                      {' '}
                      <br />
                      <a
                        href="/solutions/continuous-authentication"
                        className="mainlink"
                      >
                        Continuous Authentication
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/multi-factor-authentication"
                        className="mainlink"
                      >
                        Silent 2FA
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/device-fingerprinting"
                        className="mainlink"
                      >
                        Device Fingerprinting
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/captcha-bot-detection"
                        className="mainlink"
                      >
                        CAPTCHA & Bot Detection
                      </a>
                      ,
                      {' '}
                      and
                      {' '}
                      <a
                        href="/solutions/chrome-extension"
                        className="mainlink"
                      >
                        Chrome Extension
                      </a>.
                    </h4>
                  </div>
                </div>
              </div>
            </Section>
          </div>
        </div>
        <Awards />
        <Section
          title="How Graboxy Protects You From Remote Access Scams"
          className={styles.Protect}
        >
          <div className="container">
            <div className="row mb-3">
              <div className="col-lg-6">
                <p>
                  Cursor movements and typing through a remote agent are
                  instantly mirrored on the user’s screen.
                  However,
                  {' '}
                  <strong>some specific minor inconsistencies</strong>
                  {' '}
                  in the cursor and typing data, completely undetectable to a
                  human observer,
                  {' '}
                  <strong>characterize a remote desktop session.</strong>
                </p>
                <br />
                <p>
                  Graboxy's patent-pending technology continuously monitors user
                  interactions and uses AI to analyze data transmitted through
                  the user’s web browser. It is able to
                  {' '}
                  <strong>
                    identify a remote access session within a few seconds.
                  </strong>
                  {' '}
                  Such sessions are typically indicative of fraudulent
                  activities in online banking and payment applications.
                </p>
                <br />
                <p>
                  Graboxy is ready to spot remote access sessions
                  {' '}
                  <strong>without model training</strong>
                  {' '}
                  and calibration.
                </p>
              </div>
              <div className="col-lg-6 px-5 d-flex">
                <img src={cybercriminal} className={styles.Image} />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 pt-3 text-center">
                <Link to="Contact" className="btn" spy smooth>
                  Book a Demo
                </Link>
              </div>
            </div>
          </div>
        </Section>
        <Section title="Example Of A Typical Remote Access Scam">
          <div className="container pb-lg-3">
            <div className="row text-center mt-4">
              <div className="col-lg-3 mb-lg-0 mb-4">
                <div className={styles.Polygon}>
                  <div>
                    <img src={phone} />
                  </div>
                </div>
                <p>
                  1. The victim receives a call from someone claiming to be tech
                  support, alleging issues with the victim's account.
                </p>
              </div>
              <div className="col-lg-3 mb-lg-0 mb-4">
                <div className={styles.Polygon}>
                  <div>
                    <img src={document} />
                  </div>
                </div>
                <p>
                  2. The scammer gains trust and creates urgency by scaring the
                  victim with a fake story.
                </p>
              </div>
              <div className="col-lg-3 mb-lg-0 mb-4">
                <div className={styles.Polygon}>
                  <div>
                    <img src={error} />
                  </div>
                </div>
                <p>
                  3. The scammer convinces the victim to install a remote
                  desktop application on the device.
                </p>
              </div>
              <div className="col-lg-3">
                <div className={styles.Polygon}>
                  <div>
                    <img src={fraudulent} />
                  </div>
                </div>
                <p>
                  4. The hacker takes control of the victim's account and
                  secretly executes fraudulent payments.
                </p>
              </div>
            </div>
          </div>
        </Section>
        <Section title="Signs of remote access scams">
          <div className="container my-5">
            <div className="row mt-lg-5 mt-4">
              <div className="col-lg-4 mb-3">
                <div className={styles.Box}>
                  <img src={support} width={100} className="mb-3" />
                  <p>
                    Unsolicited calls or
                    {' '}
                    <br />
                    emails offering tech support
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className={styles.Box}>
                  <img src={access} width={100} className="mb-3" />
                  <p>
                    Urgency and pressure tactics
                    {' '}
                    <br />
                    to grant remote access
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className={styles.Box}>
                  <img src={system} width={100} className="mb-3" />
                  <p>
                    Unexpected pop-ups
                    {' '}
                    <br />
                    claiming your system is infected
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section
          title="Did you know?"
          className={styles.DidYouKnow}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mt-4">
                <p>
                  Several terms are used for Remote Access Scams, such as RDP
                  (Remote Desktop Protocol) Attack or RAT (Remote Access
                  Trojan).
                  {' '}
                  <br />
                  It's also called AnyDesk scam, after the most common remote
                  desktop software.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4 mb-3">
                <div className={styles.Box}>
                  <div className="mb-4">
                    <img src={coins} height={100} />
                  </div>
                  <p>
                    In 2022, the total losses reported to the FBI
                    {' '}
                    <br />
                    from remote access scams
                    {' '}
                    <br />
                    was $806 million in the USA.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className={styles.Box}>
                  <div className="mb-4">
                    <img src={graph} height={100} />
                  </div>
                  <p>
                    In the most recent annual report, there has been a rise of
                    over 230% in remote access scams compared to the prior year.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className={styles.Box}>
                  <div className="mb-4">
                    <img src={chart} height={100} />
                  </div>
                  <p>
                    An estimated 22% of adults in the USA
                    {' '}
                    <br />
                    fell victim to account takeover fraud.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 pt-3 text-center">
                <Link to="Contact" className="btn" spy smooth>
                  Book a Demo
                </Link>
              </div>
            </div>
          </div>
        </Section>
        <div className={styles.Blog}>
          <Blog />
        </div>
        <Contact />
      </div>
    </AppLayout>
  );
};

export default RemoteDesktopDetection;
